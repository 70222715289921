<!-- User Forgot Password component rendered through AuthView -->

<template>
  <div class="forgot-pass-form">
    <h3>Forgot Password?</h3>
    <router-link to="/auth/login">back to login</router-link>
    <p class="mt-3 text-sm">
      Create a new one as I don't have the facilities to fix it for now big man.
    </p>
  </div>
</template>

<script>
export default {
  name: "UserFPwD",
};
</script>
